import ApiService from "@/core/services/api.service.js";

const filterService = {
    getFilterGroups() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/filterGroups")
            .then(function(response) {
              if (response.data) {
                resolve(response.data[0]);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getFilterGroupById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/filterGroups/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createFilterGroup(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/filterGroups/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateFilterGroup(data) {
        return  new Promise((resolve, reject) => {
          ApiService.put("api/admin/filterGroups/update/"+data.id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteFilterGroup(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/filterGroups/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    //filters
    getFilters(param = {}) {
      var paramStr = ''
      if(param.page != undefined){
        paramStr = '?page='+(param.page+1)
      }else{
        paramStr = '?'
      }
      for (const key in param.filters) {
        if(param.filters[key] != null)
          paramStr += '&' + key + '=' + param.filters[key]
      }

      return  new Promise((resolve, reject) => {
        ApiService.get("api/admin/filters"+paramStr)
          .then(function(response) {
            if (response.data) {
              resolve(response.data[0]);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            resolve("");
            reject(error)
          });
      });
    },
    getFilterById(id) {
      return  new Promise((resolve, reject) => {
        ApiService.get("api/admin/filters/detail/"+id)
          .then(function(response) {
            if (response.data) {
              resolve(response.data.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {
            reject(error)
          });
      });
    },
    createFilter(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/filters/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateFilter(data,id) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/filters/update/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteFilter(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/filters/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
}

export default filterService;